import styled from 'styled-components';
import {
  TOUCHABLE_STYLE,
  fontXXS,
  fontXXSContrast,
  plPlusAnimation,
} from 'components/styles';
import { Link } from 'components/atoms';

export const StyledLink = styled(Link)<{ highContrast: boolean }>`
  ${fontXXS}
  color: ${({ theme, highContrast }) =>
    highContrast ? theme.new.content : theme.new.information.background};
  ${TOUCHABLE_STYLE}
  cursor: pointer;
`;

export const ServiceFee = styled.div<{ highContrast: boolean }>`
  box-sizing: border-box;
  ${fontXXS}
  color: ${({ theme, highContrast }) =>
    highContrast ? theme.new.content : theme.new.subContent};
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StrikethroughServiceFeeText = styled.span`
  text-decoration: line-through;
  margin-left: ${({ theme }) => theme.spacing.customSpacing('2px')};
`;

export const IconWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.customSpacing('2px')};
  margin-right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${TOUCHABLE_STYLE}
`;

export const ServiceFeeInfoWrapper = styled.div`
  ${fontXXS}
  color: ${({ theme }) => theme.palette.createMatch.modal.header};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const PlPlusText = styled.span`
  ${fontXXSContrast}
  ${plPlusAnimation}
  margin-right: ${({ theme }) => theme.spacing.customSpacing('2px')};
  margin-left: ${({ theme }) => theme.spacing.customSpacing('2px')};
`;
