import { Label } from 'components/atoms/label';
import { AvailableIcons } from 'components/styles';
import { useUser } from 'components/util/user-context';
import { PlrRatingFragment } from 'graphpl/core';
import React from 'react';

export type NewRelativeSkillProps = {
  gameSeriesId?: string | null;
  opponentUserId?: string | null;
  opponentRatings?: (PlrRatingFragment | null)[] | null;
  type: 'badge' | 'tile';
  context: 'matchmaking' | 'challenge';
};

type Rating =
  | 'unrated'
  | 'muchEasier'
  | 'easier'
  | 'balanced'
  | 'challenging'
  | 'veryChallenging';

type Bands = '1' | '2' | '3' | '4' | '5';

const copyMap: Record<Rating, string> = {
  unrated: 'Unrated',
  muchEasier: 'Much Easier',
  easier: 'Easier',
  balanced: 'Balanced',
  challenging: 'Challenging',
  veryChallenging: 'Very Challenging',
};

const iconMap: Record<Rating, AvailableIcons> = {
  unrated: AvailableIcons.UNRATED,
  muchEasier: AvailableIcons.MUCH_EASIER,
  easier: AvailableIcons.EASIER,
  balanced: AvailableIcons.BALANCED,
  challenging: AvailableIcons.CHALLENGING,
  veryChallenging: AvailableIcons.VERY_CHALLENGING,
};
const matchups: Record<Bands, Record<Bands, Rating>> = {
  1: {
    1: 'balanced',
    2: 'balanced',
    3: 'challenging',
    4: 'veryChallenging',
    5: 'veryChallenging',
  },
  2: {
    1: 'balanced',
    2: 'balanced',
    3: 'balanced',
    4: 'challenging',
    5: 'veryChallenging',
  },
  3: {
    1: 'easier',
    2: 'balanced',
    3: 'balanced',
    4: 'balanced',
    5: 'challenging',
  },
  4: {
    1: 'muchEasier',
    2: 'easier',
    3: 'balanced',
    4: 'balanced',
    5: 'balanced',
  },
  5: {
    1: 'muchEasier',
    2: 'muchEasier',
    3: 'easier',
    4: 'balanced',
    5: 'balanced',
  },
};

const calculateRelativePlr = (
  userPLRBand: number,
  opponentPLRBand: number,
  context: 'matchmaking' | 'challenge',
): Rating | null => {
  if (
    !opponentPLRBand ||
    opponentPLRBand === 0 ||
    `${opponentPLRBand}` === 'N/A'
  ) {
    return 'unrated';
  }

  if (
    typeof userPLRBand !== 'number' ||
    typeof opponentPLRBand !== 'number' ||
    userPLRBand < 1 ||
    userPLRBand > 5 ||
    opponentPLRBand < 1 ||
    opponentPLRBand > 5
  ) {
    return null;
  }

  const relativeRating =
    matchups[userPLRBand.toString() as Bands][
      opponentPLRBand.toString() as Bands
    ];

  if (context === 'challenge') return relativeRating;

  if (context === 'matchmaking') {
    if (relativeRating === 'veryChallenging') {
      return 'challenging';
    }
    if (relativeRating === 'muchEasier' || relativeRating === 'easier') {
      return 'balanced';
    }
  }

  return relativeRating;
};
export const NewRelativeSkill = ({
  gameSeriesId,
  opponentUserId,
  opponentRatings,
  type,
  context = 'challenge',
}: NewRelativeSkillProps) => {
  const { user } = useUser();
  if (!user) return null;
  if (!gameSeriesId) return null;
  if (!opponentUserId || opponentUserId === user.id) return null;

  const opponentPlrRating = opponentRatings?.find(
    (plrRating) => plrRating?.gameSeries?.id === gameSeriesId,
  );

  if (!opponentPlrRating || `${opponentPlrRating.plrBand?.key}` === 'N/A')
    return (
      <Label
        type={'unrated' as Rating}
        size="small"
        content={type === 'badge' ? copyMap.unrated : undefined}
        startIcon={iconMap.unrated}
      />
    );

  const usersPlrRating = user?.plrRatings?.find(
    (plrRating) => plrRating?.gameSeries?.id === gameSeriesId,
  );

  // TODO: If the user has no rating, show something relavant
  if (!usersPlrRating) return null;

  const rating = calculateRelativePlr(
    usersPlrRating?.plrBand?.key || 0,
    opponentPlrRating?.plrBand?.key || 0,
    context,
  );

  if (!rating) return null;

  return (
    <Label
      type={rating}
      size="small"
      content={type === 'badge' ? copyMap[rating] : undefined}
      startIcon={iconMap[rating]}
    />
  );
};
