import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  initPusherSocket,
} from 'helpers/pusher';
import { UserFragment, useGetCurrentUserLazyQuery } from 'graphpl/core';
import navigate from 'helpers/navigate';

type UserProviderProps = {
  user: UserFragment;
  authenticated: boolean;
};

type UserContextType = {
  user: UserFragment | null;
  authenticated: boolean;
  setUserRaw: React.Dispatch<React.SetStateAction<UserFragment>>;
};

const POLL_INTERVAL_IN_MS = 60 * 1000;
const DISABLED_POLLING = 3600 * 1000;

export const UserContext = createContext<UserContextType>({
  user: null,
  authenticated: false,
  setUserRaw: () => {},
});

export const UserProvider = ({
  user,
  authenticated,
  children,
}: PropsWithChildren<UserProviderProps>) => {
  const [userState, setUserRaw] = useState<UserFragment>(user);

  const [queryCurrentUser] = useGetCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: authenticated ? POLL_INTERVAL_IN_MS : DISABLED_POLLING,
    onCompleted: (data) => {
      if (!data.currentUser) return;
      setUserRaw(data.currentUser);
    },
  });

  useEffect(() => {
    if (authenticated) {
      queryCurrentUser();
    }
  }, [authenticated]);

  useEffect(() => {
    initPusherSocket();

    if (!userState) return;
    if (Object.keys(userState).length === 0) return;

    const channelName = `global-${userState.id}`;
    const channel = subscribeToChannel(channelName);

    const matchChannelName = `${userState.id}-lounge`;
    const matchChannel = subscribeToChannel(matchChannelName);

    bindChannel<string>(channel, 'balance-update', (info) => {
      let parsedInfo;
      if (typeof info === 'string') {
        parsedInfo = JSON.parse(info || '{}');
      } else {
        parsedInfo = info;
      }
      const { balance: updatedBalance } = parsedInfo;

      setUserRaw((prev) => {
        if (!prev) return prev;
        if (prev.balance === updatedBalance) return prev;
        return {
          ...prev,
          balance: updatedBalance,
        };
      });
    });

    bindChannel<string>(channel, 'wallet-update', (info) => {
      if (typeof info !== 'string') {
        return;
      }
      if (JSON.parse(info || '{}').refetch) {
        queryCurrentUser();
      }
    });

    bindChannel<string>(matchChannel, 'match', (info) => {
      let parsedInfo;
      if (typeof info === 'string') {
        parsedInfo = JSON.parse(info || '{}');
      } else {
        parsedInfo = info;
      }
      const { contestId, 'pn-notification': notification } = parsedInfo;

      if (notification.includes('Lounge match has started')) {
        navigate(`/matches/${contestId}`);
      }
    });

    const cleanup = () => {
      unbindChannel(channel, 'balance-update');
      unbindChannel(channel, 'wallet-update');
      unbindChannel(matchChannel, 'match');
    };

    return cleanup;
  }, [userState?.id]);

  return (
    <UserContext.Provider
      value={{ user: userState, authenticated, setUserRaw }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
