import Link from 'components/atoms/link';
import styled from 'styled-components';
import {
  MAX_PHONE,
  MIN_TABLET,
  visuallyHidden,
  BASE_SPACING,
  BASE_SPACING_AND_HALF,
  QUARTER_BASE_SPACING,
  customSpacing,
  fontS,
} from 'components/styles';
import UserHeaderDropdownMenu from './user-header-dropdown-menu';

export const PaddingBlock = styled.div`
  width: ${BASE_SPACING};
  @media (min-width: ${MIN_TABLET}) {
    display: none;
  }
`;

export const MobileDropdownMenu = styled(UserHeaderDropdownMenu)`
  padding: 0 ${BASE_SPACING};
  @media (min-width: ${MIN_TABLET}) {
    ${visuallyHidden};
  }
`;

export const MobileDirectMessages = styled.div`
  padding-top: ${QUARTER_BASE_SPACING};
  @media (min-width: ${MIN_TABLET}) {
    ${visuallyHidden};
  }
`;

export const TopLeftMenuWrap = styled.div`
  display: flex;
  height: 100%;
`;

export const TwitchLandscape = styled.div`
  @media screen and (orientation: landscape) {
    @media (max-height: ${MAX_PHONE}) {
      ${visuallyHidden};
    }
  }
`;

export const LoggedOutWrap = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const LoggedInWrap = styled(LoggedOutWrap)`
  @media (max-width: ${MAX_PHONE}) {
    ${visuallyHidden};
  }
`;

export const HeaderWrap = styled.div`
  height: ${customSpacing('50px')};
  background-color: ${({ theme }) => theme.palette.background.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 ${BASE_SPACING};
`;

export const SignedInHeaderWrap = styled(HeaderWrap)`
  @media (max-width: ${MAX_PHONE}) {
    padding: 0;
    padding-right: ${BASE_SPACING};
  }
`;

export const StyledLink = styled(Link)`
  height: 100%;
  display: flex;
  align-self: flex-start;

  justify-content: center;
  flex-direction: column;

  white-space: nowrap;
`;

export const LeftSignedInWrap = styled.div`
  height: 100%;
  display: flex;
  align-self: flex-start;

  justify-content: center;
  flex-direction: row;

  white-space: nowrap;
`;

export const LogoWithoutLink = styled.div`
  display: flex;
  width: ${customSpacing('157px')};
`;

export const Logo = styled(StyledLink)`
  width: ${customSpacing('157px')};
`;

export const SytledLinkWithPadding = styled(StyledLink)`
  padding: 0 ${BASE_SPACING_AND_HALF};
`;

export const BannerText = styled.div`
  padding-bottom: ${BASE_SPACING};
  ${fontS}
`;

export const UserEmail = styled.span`
  font-weight: 700;
`;

export const DepositButton = styled.button`
  width: fit-content;
  padding: ${({ theme }) =>
    `${theme.spacing.QUARTER_BASE_SPACING} ${theme.spacing.BASE_SPACING}`};
  background-color: ${({ theme }) => theme.palette.info.background};
  border-radius: ${({ theme }) => theme.spacing.customSpacing('24px')};
  text-align: center;
`;

export const DepositButtonText = styled.span`
  color: ${({ theme }) => theme.palette.text.alternative};
  ${fontS};
`;
